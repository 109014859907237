import { createPinia as createPiniaOrigin } from 'pinia';
import { PiniaLogger } from 'pinia-logger';
import SentryMonitoringPlugin from './plugins/sentry-monitoring-plugin/SentryMonitoringPlugin';
function createPinia() {
    return createPiniaOrigin()
        .use(PiniaLogger({
        expanded: false,
        disabled: process.env.NODE_ENV === 'production',
    }))
        .use(SentryMonitoringPlugin({
        disabled: process.env.NODE_ENV === 'production',
        ignore: ['auth'],
    }));
}
export default createPinia;
