import { computed, ref } from 'vue';
import useI18n from '@/composables/useI18n';
import { useAuthStore } from '@/stores/auth/auth';
import { getFormatedError } from '../utils/formatAuthError';
/**
 * Hook for handling login form functionality.
 *
 * @param {Object} accountData - Account data object.
 * @param {Function} getCaptchaToken - Function to get captcha token.
 * @param {Function} errorProcessor - Function to process auth error message.
 * @returns Login form state and methods.
 */
export const useLoginForm = (accountData, getCaptchaToken, errorProcessor) => {
    const authStore = useAuthStore();
    const { translate } = useI18n();
    const expiredUsername = computed(() => { var _a; return (_a = authStore.multiUser) === null || _a === void 0 ? void 0 : _a.username; });
    const keepLoggedIn = ref(false);
    const username = ref('');
    const password = ref('');
    const loginError = ref('');
    const isLoadingLogin = ref(false);
    const readonlyLogin = ref(false);
    const googleCode = ref();
    const submit = async (captchaV2CheckboxToken) => {
        loginError.value = '';
        isLoadingLogin.value = true;
        const captchaToken = await getCaptchaToken(captchaV2CheckboxToken);
        await authStore.signIn({
            credentials: {
                password: password.value,
                username: username.value,
            },
            keepLoggedIn: keepLoggedIn.value,
            captchaToken,
            isCaptchaV2Checkbox: typeof captchaV2CheckboxToken === 'string',
            accountData: accountData,
        })
            .catch((error) => {
            isLoadingLogin.value = false;
            if (error instanceof Error || typeof Error === 'string') {
                const errorObj = getFormatedError(error, translate);
                loginError.value = errorObj.message;
                if ('action' in errorObj) {
                    errorProcessor(errorObj.action);
                }
            }
            else {
                throw error;
            }
        });
    };
    return {
        password,
        username,
        isLoadingLogin,
        expiredUsername,
        keepLoggedIn,
        loginError,
        readonlyLogin,
        submit,
        googleCode,
    };
};
