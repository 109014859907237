import { onErrorCaptured, ref } from 'vue';
import useI18n from '../composables/useI18n';
import TranslatableError from '../errors/TranslatableError';
import SentryApi from '../sentry';
const { translate } = useI18n();
const errorText = ref('');
onErrorCaptured((error) => {
    if (error instanceof TranslatableError) {
        errorText.value = translate(error.translationKey);
        console.warn(error.innerError);
        SentryApi.captureException(error.innerError);
    }
    else {
        errorText.value = translate('errors.commonText');
        console.warn(error);
        SentryApi.captureException(error);
    }
    return false;
});
const discardError = () => {
    errorText.value = '';
};
const showError = (message) => {
    errorText.value = message;
};
export function useErrorHandler() {
    return {
        errorText,
        discardError,
        showError,
    };
}
